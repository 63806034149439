<template>
  <div id="home">
    <div class='header'>
      <div @click="routerTo(1)" class="header_logo">
        <img src="@/assets/image/logo.png" alt="">
      </div>
      <div class="header_item">
        <div v-for="item in navData" :key="item.id" @click="routerTo(item.id)" class="header_item_content"><span :class="currentIndex === item.id ? 'active' : ''">{{item.class_name}}</span></div>
      </div>
    </div>
    <div class="nav">
      <div class="meifan">MEIFAN AUSTRALIA</div>
      <div class="sustainable">Sustainable</div>
      <div class="service">Brand Management Service</div>
      <div class="point"><span class="point_l"></span><span class="point_r"></span></div>
      <div class="company">全生态<span>品牌管理公司</span></div>
      <div @click="toContact" class="contact"><Icon type="ios-mail-outline" size="24" />{{contactUs.class_name}}<Icon type="md-arrow-forward" size="18" /></div>
    </div>
    <div class="bg"><img src="@/assets/image/Home.png" alt=""></div>
  </div>
</template>

<script>
import {getNav} from '@api'

export default {
  data () {
    return {
      navData: [],
      contactUs: {},
      currentIndex: 1
    }
  },
  created() {
    const newData = JSON.parse(localStorage.getItem('navData'))
    if(!newData) {
      this.getNavData()
    }else {
      if(new Date().getTime() - newData.time >  5 * 60 * 1000) {
        this.getNavData()
      }else {
        this.navData = newData.menu
        this.contactUs = newData.menu_contact_us[0]
      }
    }
  },
  methods: {
    routerTo(id) {
      if(id === 1) this.getNavData()
      if(id === 2) this.$router.push({name: 'About'})
      if(id === 3) this.$router.push({name: 'Business'})
      if(id === 4) this.$router.push({name: 'News'})
    },
    toContact() { // 跳转联系我们页面
      this.$router.push({name: 'Contact'})
    },
    getNavData() { // 获取导航数据
      getNav().then(res => {
        const {menu, menu_contact_us} = res.data
        const navData = res.data
        this.navData = menu
        this.contactUs = menu_contact_us[0]
        navData.time = new Date().getTime()
        localStorage.setItem('navData', JSON.stringify(navData))
      })
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  width: 1920px;
  height: 1080px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-width: 1200px;
    height: 90px;
    padding: 0 136px;
    color: #000;
    .header_logo {
      animation-name: header_left_shift;
      animation-duration: 1s;
      cursor: pointer;
    }
    .header_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      animation-name: header_right_shift;
      animation-duration: 1s;
      .header_item_content {
        width: 185px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        span {
          position: relative;
          padding-bottom: 8px;
          font-weight: bold;
        }
        .active {
          border-bottom: 6px solid #00C0F8;
        }
        .active::before {
          content: '';
          position: absolute;
          top: -16px;
          left: 0;
          width: 8px;
          height: 8px;
          background: #43A3E0;
        }
        .active::after {
          content: '';
          position: absolute;
          top: -16px;
          left: 16px;
          width: 8px;
          height: 8px;
          background: #D90D0D;
        }
      }
      .header_item_content:hover {
        color: #00C0F8;
      }
    }

  }
  @keyframes header_left_shift {
    0% {
      transform: translateX(-136px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes header_right_shift {
    0% {
      transform: translateY(-90px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .nav {
    position: relative;
    width: 100%;
    min-width: 1200px;
    height: 990px;
    color: #191919;
    text-transform: uppercase;
    .meifan {
      position: absolute;
      top: 178px;
      left: 136px;
      font-size: 24px;
      font-weight: 600;
      word-spacing: 10px;
      letter-spacing: 8px;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: .3s;
      animation-fill-mode: backwards;
    }
    .sustainable {
      position: absolute;
      top: 266px;
      left: 136px;
      font-size: 60px;
      font-weight: 600;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: .6s;
      animation-fill-mode: backwards;
    }
    .service {
      position: absolute;
      top: 340px;
      left: 136px;
      font-size: 60px;
      font-weight: 600;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: .6s;
      animation-fill-mode: backwards;
    }
    .point {
      position: absolute;
      top: 466px;
      left: 136px;
      width: 100%;
      height: 16px;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: .9s;
      animation-fill-mode: backwards;
      .point_l {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: #00C0F8;
        margin-right: 8px;
      }
      .point_r {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: #D90D0D;
      }
    }
    .company {
      position: absolute;
      top: 546px;
      left: 136px;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 2px;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: 1.2s;
      animation-fill-mode: backwards;
      span {
        font-size: 32px;
      }
    }
    .contact {
      position: absolute;
      top: 730px;
      left: 136px;
      width: 210px;
      height: 55px;
      color: #fff;
      background: #000;
      font-size: 14px;
      text-align: center;
      line-height: 55px;
      cursor: pointer;
      animation-name: header_left_shift;
      animation-duration: 1s;
      animation-delay: 1.5s;
      animation-fill-mode: backwards;
      transition: 1s;
      .ivu-icon-ios-mail-outline {
        padding-right: 14px;
      }
      .ivu-icon-md-arrow-forward {
        padding-left: 25px;
      }
    }
    .contact:hover {
      color: #000;
      background: #fff;
      border: 1px solid #000;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 57%;
    height: 92%;
    border-bottom-left-radius: 278px;
    overflow: hidden;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>